<template>
    <GCCommonForm
        ref="form"
        v-bind="attrs"
        v-on="listeners"
        @update:isFormValid="updateIsFormValid"
        formType="consumption"
        :id="id"
        :document="document"
    >
        <template #table-actions>
            <slot name="table-actions"></slot>
        </template>
    </GCCommonForm>
</template>

<script>
import GCCommonForm from './GC_CommonForm'

export default {
    name: 'ConsumptionForm',
    inheritAttrs: false,
    props: {
        id: String,
        document: String,
    },
    components: {
        GCCommonForm,
    },
    methods: {
        updateIsFormValid(v) {
            this.$emit('update:isFormValid', v)
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>