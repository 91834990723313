<template>
    <v-container fluid class="pt-0">
        <span class="pl-5 pl-md-12 section-title">{{ $t('views.consumption.title') }}</span>
        <ConsumptionForm
            ref="form"
            v-model="consumption"
            :loading="loading"
            :isFormValid.sync="isFormValid"
            @submit="submit(consumption)"
            @typeLoading="value => typeLoading = value"
            @selected-customer="value => isCustomerSelected = !!value"
            :id="id"
            :document="document"
        >
            <template #table-actions>
                <div class="mx-md-4 pb-6">
                    <!-- Versão desktop -->
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-end">
                        <v-btn @click="$router.go(-1)" class="action-button" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>

                        <v-btn depressed class="action-button" type="submit" :loading="loading" :disabled="!isFormValid || !hasSpentValue" :label="$t('views.consumption.generate')" color="primary">
                            {{ $t('views.consumption.generate') }}
                        </v-btn>
                    </div>
                    <!-- Versão mobile -->
                    <div v-else class="mx-4">
                        <v-btn depressed class="action-button-mobile" type="submit" :loading="loading" :disabled="!isFormValid || !hasSpentValue" :label="$t('views.consumption.generate')" color="primary">
                            {{ $t('views.consumption.generate') }}
                        </v-btn>

                        <v-btn @click="$router.go(-1)" class="mt-2 action-button-mobile" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </ConsumptionForm>

        <v-alert type="error" v-if="errorMessage" class="mb-0 mt-3" transition="fade-transition">
            {{ errorMessage }}
        </v-alert>
    </v-container>
</template>

<script>
import ConsumptionForm      from '@/components/forms/ConsumptionForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Consumption          from '@/models/Consumption'

export default {
    name: 'ConsumptionView',
    mixins: [ HasErrorHandlerMixin ],
    props: {
        id: String,
        document: String,
    },
    data: vm => ({
        isFormValid: false,
        loading    : false,
        consumption: new Consumption({ amount: 1 }),
        errorMessage: null,
        typeLoading: false,
    }),
    components: {
        ConsumptionForm,
    },
    methods: {
        async submit(consumption) {
            this.errorMessage = null

            if (!this.isFormValid || !consumption)
                return

            this.loading = true
            let res = await consumption.consumption()
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.consumption.success'), 'success')
            this.$router.push({ name: 'customers.details', params: { id: consumption.customer_id, document: consumption.customer_document } })
        },

        async preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')

            if (e.message == 'Network Error') {
                this.errorMessage = this.$t('views.consumption.errors.network_error')
                return
            }

            if (status == 422) {
                let errorType = this.$lodash.get(e, 'response.data.type')

                let errorMessages = {
                    'usage_limit_reached'                       : this.$t('views.consumption.errors.usage_limit_reached'),
                    'customer_last_consumption_still_processing': this.$t('views.consumption.errors.customer_last_consumption_still_processing'),
                    'customer_already_has_intention'            : this.$t('views.consumption.errors.customer_already_has_intention'),
                    'insufficient_funds'                        : this.$t('views.consumption.errors.insufficient_funds'),
                }

                this.errorMessage = errorMessages[errorType] || this.$t('views.consumption.errors.generic')

                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.errorMessage, 'error', resolve)
                })

                return
            }

            this.errorMessage = this.$t('views.generate.errors.generic')

            this.errorHandler(e)
        }
    },
    computed: {
        hasSpentValue() {
            return this.consumption.products.some(product => product.value > 0)
        },
    },
}
</script>

<style lang="scss" scoped>
.action-button {
    width: 140px;
}

.action-button-mobile {
    width: 100%;
}
</style>